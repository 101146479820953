import React, {Suspense, useEffect} from 'react';
import isLocalStorageAvailable from '../../common/utils/isLocalStorageAvailable';
import {
  B2CComponentsProvider,
  PageLoadingError,
  PageLoadingIndicator,
} from '@edekadigital/b2c-components';
import Cookies from 'js-cookie';

const INITIALIZATION_ERROR_TITLE = 'Fehler beim Laden';

const INITIALIZATION_ERROR_MESSAGE_COOKIES =
  'Diese Seite verwendet Cookies. Bitte aktivieren Sie Cookies in Ihrem Browser und versuchen sie es erneut.';

export const App: React.FC = () => {
  useEffect(() => {
    try {
      if (!window.location.search) {
        return;
      }

      const urlSearchParams = new URLSearchParams(window.location.search);
      const sessionId = urlSearchParams.get('sessionId');
      if (sessionId) {
        Cookies.set('SESSION_ID', sessionId);
      }
      const dId = urlSearchParams.get('dId');
      if (dId) {
        Cookies.set('D_ID', dId);
      }
    } catch (e) {
      console.error('some error', e);
    }
  }, []);
  if (!isLocalStorageAvailable()) {
    return (
      <B2CComponentsProvider>
        <PageLoadingError
          title={INITIALIZATION_ERROR_TITLE}
          message={INITIALIZATION_ERROR_MESSAGE_COOKIES}
        />
      </B2CComponentsProvider>
    );
  }

  const AppComponent = React.lazy(() => import('../../components/app/App'));
  return (
    <Suspense
      fallback={
        <B2CComponentsProvider>
          <PageLoadingIndicator />
        </B2CComponentsProvider>
      }
    >
      <AppComponent />
    </Suspense>
  );
};

export default App;
